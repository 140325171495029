import React from "react"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import pin from "../assets/pin.png"

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: 49.7762507, lng: 14.005558 }}
  >
    <Marker
      position={{ lat: 49.7762507, lng: 14.005558 }}
      options={{ icon: { url: `${pin}`, scaledSize: {width: 32, height: 48}}}}
    />
  </GoogleMap>
))

export default MapWithAMarker
