import React from "react"
import navStyles from "./nav.module.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Nav = () => {
	return (
		<aside className={navStyles.nav}>
		  	<ul className={navStyles.navList}>
		  		<li className={navStyles.navItem}>
		  			<AnchorLink className={navStyles.navLink} to="#uvod" title="Úvod" />
		  		</li>

		  		<li className={navStyles.navItem}>
		  			<AnchorLink className={navStyles.navLink} to="#strojni-vybaveni" title="Strojní vybavení" />
		  		</li>

		  		<li className={navStyles.navItem}>
		  			<AnchorLink className={navStyles.navLink} to="#kontakt" title="Kontakt" />
		  		</li>
		  	</ul>	
	  	</aside>
	)
}

export default Nav
