import React from "react"
import "normalize.css"
import Sidebar from "../components/sidebar"
import Main from "../components/main"
import layoutStyles from "./index.module.css"
import { Helmet } from "react-helmet"
import favicon from "../assets/favicon.ico"

const Index = () => {
  return (
    <div className={layoutStyles.layout}>
    	<Helmet>
            <meta charSet="utf-8" />
            <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content="Adresa provozovny Nástrojárny Čepelák: Milan Čepelák, Areál strojíren č.p. 19, 262 23 Čenkov." />
            <title>Nástrojárna Čepelák</title>
        </Helmet>

    	<Main className={layoutStyles.main} />
    	<Sidebar />
    </div>
  )
}

export default Index

