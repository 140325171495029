import React from "react"
import logoStyles from "./logo.module.css"
import euLogo from "../assets/dotace.png"
import mpoLogo from "../assets/mpo.png"

const Logo = () => {
	return (
        <div className={logoStyles.wrapper}>
            <div className={logoStyles.logoWrapper}>
    		  	<img
                    className={logoStyles.euLogo}
                    width="420"
                    height="131"
                    alt="Evropský fond pro regionální rozvoj"
                    src={euLogo}
                />

                <img
                    className={logoStyles.mpoLogo}
                    width="180"
                    height="85"
                    alt="Ministerstvo průmyslu a obchodu"
                    src={mpoLogo}
                />
            </div>

            <p className={logoStyles.subheading}>Hlavním cílem projektu je pořízení CNC soustružnického centra, sloužícího k soustružení rotačních dílů.</p>
            <p className={logoStyles.subheading}>Nákup nové technologie se projeví primárně ve zvýšení efektivnosti výrobních a pracovních procesů.</p>
        </div>
	)
}

export default Logo
