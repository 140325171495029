import React from "react"
import Nav from "./nav"
import sidebarStyles from "./sidebar.module.css"
import logo from "../assets/logo.v1.wide_.png"

const Sidebar = props => {
  return (
    <div className={sidebarStyles.sidebar}>
    	<img
    		className={sidebarStyles.logo}
    		width="210"
    		height="70"
    		alt="Nástrojárna Čepelák Logo"
    		src={logo}
    	/>

		<Nav />

        <div className={sidebarStyles.sidebarContent}>
    		<p className={sidebarStyles.sidebarFooter}>Copyright © 2019 Nástrojárna Čepelák</p>
        </div>
    </div>
  )
}

export default Sidebar
