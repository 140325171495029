import React from "react"
import Hero from "./hero"
import Logo from "./logo"
import Equipment from "./equipment"
import MapWithAMarker from "./map"
import mainStyles from "./main.module.css"
import Contact from "./contact"

const GOOGLE_MAPS_KEY = process.env.GOOGLE_MAP_API_KEY
const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&v=3.exp`

const Main = props => {
  return (
    <main className={mainStyles.main}>
    	<Hero />

    	<Logo />

    	<div id="strojni-vybaveni" className={mainStyles.content}>
    		<Equipment />
    	</div>

    	<div id="kontakt">
			<MapWithAMarker
				googleMapURL={GOOGLE_MAPS_URL}
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: `500px` }} />}
				mapElement={<div style={{ height: `100%` }} />}
			/>

			<div id="napiste-nam" className={mainStyles.content}>
				<Contact />
			</div>
		</div>
    </main>
  )
}

export default Main
