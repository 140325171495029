import React from "react"
import contactStyles from "./contact.module.css"

const Contact = () => {
    return (
        <div className={contactStyles.contact}>
            <h2 className={contactStyles.title}>Nástrojárna Čepelák</h2>

            <div className={contactStyles.contactInformation}>   
                <div className={contactStyles.contactColumn}>                    
                    <p><strong>Provozovna:</strong></p>
                    <p>Areál strojíren č.p.&nbsp;19</p>
                    <p>262&nbsp;23 Čenkov</p>
                </div>

                <div className={contactStyles.contactColumn}>
                    <p className={contactStyles.spacer}><strong>Fakturační adresa:</strong></p>
                    <p>Milan Čepelák</p>
                    <p>Čenkov 164</p>
                    <p>262 23</p>
                    <p>IČO: 03773141</p>
                    <p>DIČ: CZ8210301121</p>
                </div>

                <div className={contactStyles.contactColumn}>
                    <p className={contactStyles.spacer}><strong>Kontakty:</strong></p>
                    <p>E-mail: <a href="mailto:info@nastrojarnacepelak.cz">info@nastrojarnacepelak.cz</a></p>
                    <p>Telefon: <a href="tel:+420606891375">+420 606 891 375</a></p>
                </div>
            </div>
        </div>
    )
}

export default Contact
