import React from "react"
import equipmentStyles from "./equipment.module.css"
import eq1 from "../assets/eq_1.jpg"
import eq2 from "../assets/eq_2.jpg"
import eq3 from "../assets/eq_3.jpg"
import eq4 from "../assets/eq_4.jpg"
import eq5 from "../assets/eq_5.jpg"
import eq6 from "../assets/eq_6.jpg"

const Equipment = props => {
  return (
  	<section className={equipmentStyles.section}>
	  	<h2 className={equipmentStyles.sectionTitle}>Strojní vybavení</h2>

	    <div className={equipmentStyles.wrapper}>
	    	<div className={equipmentStyles.box}>
	    		<h5 className={equipmentStyles.title}>MAS MCV 1016 Quick</h5>

	    		<h6 className={equipmentStyles.subtitle}>Obráběcí centrum</h6>

	    		<figure className={equipmentStyles.figure}>
	    			<img className={equipmentStyles.boxImg} width="180" src={eq1} alt="" />
	    		</figure>

	    		<ul className={equipmentStyles.paramList}>
	    			<li>X / Y / Z – 1 016 / 610 / 710 mm</li>
	    			<li>Max. plocha stolu: 1 300 x 600 mm</li>
	    			<li>Max. zatížení stolu: 700 Kg</li>
	    			<li>Otáčky vřetene: 10 000 min<sup>-1</sup></li>
	    		</ul>
	    	</div>

	    	
	    	<div className={equipmentStyles.box}>
	    		<h5 className={equipmentStyles.title}>FANUC C400IA</h5>

	    		<h6 className={equipmentStyles.subtitle}>Drátová řezačka</h6>

	    		<figure className={equipmentStyles.figure}>
	    			<img className={equipmentStyles.boxImg} width="180" src={eq2} alt="" />
	    		</figure>

	    		<ul className={equipmentStyles.paramList}>
	    			<li>X / Y / Z – 370 / 270 / 254 mm</li>
	    			<li>Max. plocha stolu: 700 x 600 mm</li>
	    			<li>Max. zatížení stolu: 500 Kg</li>
	    		</ul>
	    	</div>

	    	<div className={equipmentStyles.box}>
	    		<h5 className={equipmentStyles.title}>HERMLE U630T</h5>

	    		<h6 className={equipmentStyles.subtitle}>Obráběcí centrum</h6>

	    		<figure className={equipmentStyles.figure}>
	    			<img className={equipmentStyles.boxImg} width="180" src={eq3} alt="" />
	    		</figure>

	    		<ul className={equipmentStyles.paramList}>
	    			<li>X / Y / Z – 630 / 500 / 500 mm</li>
	    			<li>Max. plocha stolu: 900 x 530 mm</li>
	    			<li>Max. zatížení stolu: 400 Kg</li>
	    			<li>Otáčky vřetene: 7 000 min<sup>-1</sup></li>
	    		</ul>
	    	</div>

	    	<div className={equipmentStyles.box}>
	    		<h5 className={equipmentStyles.title}>EDM SY-2030</h5>

	    		<h6 className={equipmentStyles.subtitle}>Vrtačka</h6>

	    		<figure className={equipmentStyles.figure}>
	    			<img className={equipmentStyles.boxImg} width="180" src={eq4} alt="" />
	    		</figure>

	    		<ul className={equipmentStyles.paramList}>
	    			<li>X / Y / Z – 300 / 200 / 345 mm</li>
	    			<li>Max. plocha stolu: 410 x 210 mm</li>
	    			<li>Max. zatížení stolu: 200 Kg</li>
	    		</ul>
	    	</div>

	    	<div className={equipmentStyles.box}>
	    		<h5 className={equipmentStyles.title}>DOOSAN PUMA GT 2100 / M</h5>

	    		<h6 className={equipmentStyles.subtitle}>Soustružnické centrum</h6>

	    		<figure className={equipmentStyles.figure}>
	    			<img className={equipmentStyles.boxImg} width="180" src={eq5} alt="" />
	    		</figure>

	    		<ul className={equipmentStyles.paramList}>
	    			<li>3 řízené osy</li>
	    			<li>Oběžný průměr nad ložem 600 mm</li>
	    			<li>Průchod vřetenem 68mm</li>
	    			<li>4500 ot./ min.</li>
	    		</ul>
	    	</div>

	    	<div className={equipmentStyles.box}>
	    		<h5 className={equipmentStyles.title}>Softwarová výbava</h5>

	    		<h6 className={equipmentStyles.subtitle}>Licencovaný profesionální software</h6>

	    		<figure className={equipmentStyles.figure}>
	    			<img className={equipmentStyles.boxImg} width="180" src={eq6} alt="" />
	    		</figure>

	    		<ul className={equipmentStyles.paramList}>
	    			<li>CAD/CAM Autodesk Inventor 2020</li>
	    			<li>EdgeCAM 2017 pro drátořez</li>
	    			<li>CAD Solid Edge ST10</li>
	    		</ul>
	    	</div>
	    </div>
    </section>
  )
}

export default Equipment
