import React from "react"
import heroStyles from "./hero.module.css"
import Icon from "../../static/images/carret.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import bgImg from "../assets/background.jpeg"

const Hero = () => {
	return (
		<div id="uvod" className={heroStyles.wrapper}>
		  	<img
		  		style={{display: 'none'}}
		  		width="2500"
		  		height="1726"
		  		src={bgImg}
		  		alt="Nástrojárna Čepelák"
		  	/>

		  	<div className={heroStyles.overlay} />

		  	<div className={heroStyles.leadWrapper}>
		  		<div className={heroStyles.titleWrapper}>
				  	<h1 className={heroStyles.heading}>Nástrojárna Čepelák</h1>
				  	<h2 className={heroStyles.subheading}>Profesionální zakázková výroba na míru!</h2>

		  			<AnchorLink to="#napiste-nam" className={heroStyles.btn}>
		  				<Icon className={heroStyles.svg}/>
		  				<p className={heroStyles.btnContent}>Napište nám</p>
		  			</AnchorLink>
	  			</div>
	  		</div>
	  	</div>
	)
}

export default Hero
